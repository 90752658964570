<template>
   <div style="overflow:hidden;position:relative"><div class="main-container">
       <div style="margin-top:5rem" class="container-keyword-intro">
           <div class="intro-container">
           <div  v-on:click="goTo('/Simulateur')" class="intro-left img-cover">

               <div class="info-container">
                   <h3 class="color-swap hide_600" style="">Let the fun begin</h3>
                   <div class="info-button" v-on:click="goTo('/Simulateur')"><div>Commencer</div></div>
               </div>
           </div>
           <div class="intro-right">
               <div  v-on:click="goTo('/Shop')" class="intro-left-top dim img-cover intro-right-prop">

                   <div class="info-container">
                   <h3 style="" >La Bloomtique</h3>
                   <div class="info-button" v-on:click="goTo('/Shop')"><div>Shop</div></div>
                    </div>

               </div>
               <div  v-on:click="goTo('/Galerie')" class="intro-left-bottom dim  img-cover intro-right-prop">

                   <div class="info-container">
                   <h3 style="">Galerie</h3>
                   <div class="info-button"><div>Découvrir</div></div>
                    </div>

               </div>
           </div>


       </div>
      <div class="keyword">
           <h3 class="one" @click="GoToShopWithTag('KIDS')">Kids</h3>
           <h3 class="two"  @click="GoToShopWithTag('ADOS')">Ados</h3>
           <h3 class="tree"  @click="GoToShopWithTag('COMMERCE')">COMMERCE</h3>
           <h3 class="four"  @click="GoToShopWithTag('INTERIEUR')">Interieur</h3>
           <h3 class="five"  @click="GoToShopWithTag('MARIAGE')">Mariage</h3>
           <h3 class="six"  @click="GoToShopWithTag('INSTAGRAM')">Instagram</h3>
       </div>

       </div>



       <div class="product-container">
           <h1 class="best-seller">Best sellers</h1>

           <div v-if="!tendance_error" class="products">
                <transition v-for="(article,index) in article_tendances" :key="index" name="slide-fade" mode="out-in" appear>
                <shop-item  :article="article" />
                </transition>

           </div>
            <div v-else class="products">
              <h4>Impossible de charger les produits</h4>
            </div>
           <SimButtonCTA :callBack="GoToShop" text="Voir la Boutique"/>
       </div>

       <div class="blog-container">

            <div class="article-container">
                   <h3 class="article-title">Partenaire d'évènements créatifs</h3>

                   <p class="article-content">
Conseils, projets, réalisations et suivis des travaux en collaboration avec une équipe d'artisans expérimentés</p>


               </div>
       </div>

       <div class="pros-container">
           <div class="pros-grid">
               <div @click="goTo('/installation')" class="pro-item">
                <div class="pro-item-bg">
                    <img src="../assets/imgs/exterieur.jpg" class="pro-item-content " alt="" srcset="">
                </div>
                <div class="pro-item-message">
                    <span>INSTALLATION D'enseignes
                    </span>
                </div>
            </div>
            <div  @click="goTo('/Scenographie')" class="pro-item">
            <div class="pro-item-bg">
                    <img src="../assets/imgs/scenographie.jpg" class="pro-item-content " alt="" srcset="">
                </div>

                <div class="pro-item-message">
                    <span>décoration & scénographie</span>
                </div>
            </div>
            <div @click="goTo('/Creation')" class="pro-item">
                <div class="pro-item-bg">
                    <img src="../assets/imgs/graphique.jpg" class="pro-item-content " alt="" srcset="">
                </div>
                <div class="pro-item-message">
                    <span>création graphique</span>
                </div>
            </div>
           </div>
       </div>

     <div class="w-100 c" style="background-color:var(--white-break)">
           <icons/>
       </div>
   </div>
       <mini-modal ref="logger"><span class="info">{{message}}</span></mini-modal>
       </div>

</template>


<script>
import ShopItem from '../components/ShopItem'
import {SimButtonCTA} from '../components/buttons';
import MiniModal from '../components/MiniModal.vue';
import Icons from '../components/Icons.vue'
export default {
    name : 'Accueil',
    components : {
        ShopItem,
        SimButtonCTA,
        MiniModal,
        Icons
    },

     metaInfo : {
        title : 'Accueil',
        meta : [
            {name : 'description', content : 'Achetez des néons LED fait main, ou fabriquez votre néon personnalisé directement sur notre site'},
             { property: 'og:title', content: "Accueil"},
                { property: 'og:site_name', content: 'Neon Bloom Paris'},
                {property: 'og:type', content: 'website'},
                {name: 'robots', content: 'index,follow'},
        ]
    },
     data() {
        return {
            articleHandler : this.$articlesHandler,
            searchHandler : this.$searchHandler,
            article_tendances : [],
            tendance_error : false,
            window_width : 2000,
            email : "",
            message : "",
            abonning  : false
        }
    },
    computed: {
        Tendance()
        {
            if(this.window_width > 1286)return this.articleHandler.articles.slice(0,4);
            else if(this.window_width > 970)return this.articleHandler.articles.slice(0,3);
            return this.articleHandler.articles.slice(0,2)
        }
    },
    methods: {


        AddEmailToNewsLetter()
        {
            if(this.email == "" || !this.CheckEmail(this.email))
            {
                this.message = "L'adresse mail saisie est incorrecte";
                this.$refs.logger.Reveal("Erreur", "", 460, "var(--amaranth)");
                return;
            }
            this.abonning = true;
            this.$accountsHandler.AddEmailToNewsLetter(this.email).then(() => {
                this.message = "Merci de participer a notre belle aventure !"
                this.$refs.logger.Reveal("Email ajouté", "", 460, "#16a085");
                this.abonning = false;

            }).catch((err) => {
                if(err == "exist")
                {
                this.message = "Il semble que cette adresse mail soit déja utilisée..."

                }
                else {
                    this.message = "Une erreur inconnue est arrivée, veuillez reessayer dans quelques instants"
                }
                this.$refs.logger.Reveal("Erreur", "", 460, "var(--amaranth)");
               this.abonning = false;
            })
        },

        CheckEmail(email)
        {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
        },


        goTo : function(path)
        {

            this.$router.push(path);
        },
        GetTendance()
        {
            this.articleHandler.GetTendancesNeons()
        },

        GoToShopWithTag(tag)
        {
            this.$searchHandler.ClearTags();
            this.$searchHandler.AddTagToFilter(tag);
            this.$searchHandler.SetPage(1);
            this.$searchHandler.ApplySearch("*");
            this.GoToShop();
        },

        GoToShop()
        {

            this.goTo('/Shop');
        },
        ResizeHandler()
        {
             this.window_width = window.innerWidth;
        }
    },

    created() {
        window.addEventListener("resize", this.ResizeHandler);
        this.window_width = window.innerWidth;

        this.searchHandler.GetAccueilTendance().then((articles) => {
          this.article_tendances = articles;
        }).catch(() => {
          this.tendance_error = true;
        });
    },
    destroyed() {
        window.removeEventListener("resize", this.ResizeHandler);
    },






}
</script>

<style scoped>


@media(max-width:600px)
{
  .hide_600 {
  display: none!important;
  }
}

.pros-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width : 100%;
    padding: 1rem;
    background-color: white;
}


.pros-grid {
    padding : 0rem 1rem;

    width : 100%;
    display : grid;
    grid-template-columns : 33.33% 33.33% 33.33%;
    grid-gap : 0.5rem;
}

.pro-item {
    width : 100%;
    display : block;
    position : relative;
     overflow : hidden;
}

.pro-item-message {
    position : absolute;
    line-height: 176%;
    top : 50%;
    cursor: pointer;
    left : 50%;
    transform : translate(-50%, -50%);
    background-color: rgba(255,255,255,0.9);
    display : flex;
    align-items : center;
    justify-content : center;
    width : 65%;
    height : 35%;
    transition-duration: 0.2s;
}

.pro-item-ask {
     position : absolute;
    top : 50%;
    left : 50%;
    transform : translate(-50%, -50%);

    display : flex;
    align-items : center;
    justify-content : center;
    padding : 2rem 0;
    font-family: "Montserrat", sans-serif;
    width : 80%;
    flex-direction: column;
}

.pro-item-message > span {
    font-family: 'DIN', sans-serif;
    color : black;
    font-size : 1.75rem;
    text-transform : uppercase;
    padding: 1rem;
    text-align: center;
}

@media (max-width:750px)
{
    .pro-item-message > span {
        font-size : 1.2rem!important;
    }
}

@media (max-width:640px)
{
    .pros-grid {
        grid-template-columns : 100%!important;
    }
}

.pro-item-bg {
    width : 100%;
    background-color : var(--white-break);
    position : relative;
    height : auto;
    object-fit : cover;
    aspect-ratio: 1/1;
    transition-duration : 0.2s;
}

.pro-item-content {
    position : absolute;
    top: 0;
    left: 0;
    width : 100%;
    height: 100%;
    object-fit: cover;
}

.pro-item-bg:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.pro-item:hover > .pro-item-bg {
    transform: scale(1.05);
    cursor: pointer;
}

@media(max-width:570px)
{
    .c {
        display: none;
    }
}

.button-disabled {
    background-color : lightgray!important;
    color : gray!important;
    border: none!important;
    pointer-events : none;
}

.info {
    color : black;
    padding : 1rem;
}

.newsletter {
    border-top : 1px solid white;
    width: 100%;
    margin-top : 4rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.newsletter > h3 {
    font-size : 1.5rem;
    margin-bottom : 0!important;
}

.newsletter > span {
    font-family : 'Montserrat', sans-serif;
    margin-bottom : 0.5rem;
}

.newsletter > input {
    font-family : 'Montserrat', sans-serif;
    max-width : 450px;
    width : 100%;
    min-width: 300px;
    padding: 0.5rem 0.5rem;
}

/*####################   MAIN STYLE ##############################*/
.main-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    align-items: center;
    justify-content: center;
}



/*####################   INTRO STYLE ##############################*/
/*#region intro-style*/


@media(max-width: 800px)
{

    .intro-container {
        height: 150vw!important;
        flex-direction: column!important;
        min-height: 950px;
    }
    .intro-left {
        width: 100%!important;
        height: 33.333%!important;
    }

    .blog-container {
        max-height: unset!important;
    }

    .intro-right {
        width: 100%!important;
        height: 66.6666%!important;
    }

    .info-container > h3 {
        font-size: 2.5rem!important;
    }
    .color-swap {
        color: white!important;
    }
}

.color-swap {
    color: black;
}

.container-keyword-intro
{
    width: 92vw;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    max-width: 845px;
    align-items: center;


}

.intro-container {

    width: 100%;
    height: 68vw;
    max-height: 679px;
    display: flex;
    flex-direction: row;



}

.img-cover {
    background-size: cover;
    position: relative;
}

.intro-left {
    background-image: url("../assets/imgs/creer_neon.jpg");
    background-position: center;
    background-position-y: 0%;
    width: 60%;
    cursor: pointer;


}

.intro-right {
    width: 40%;
    display: flex;
    flex-direction: column;


}

.intro-right-prop {
    height: 50%;


}



.intro-left-top {
    background-image: url("../assets/imgs/shop.jpg");
    cursor: pointer;
}

.intro-left-bottom {
    background-image: url("../assets/imgs/galerie.jpg");
    background-position-y: center;
    cursor: pointer;
}

.info-container {
    position: absolute;
    display: flex;
    align-items: flex-start;
    width: 90%;
    flex-direction: column;
    bottom: 1.5rem;
    left: 1.5rem;
    font-family: 'DIN', sans-serif;
    text-transform: uppercase;


}

.info-container > h3 {
    font-family: 'DIN', sans-serif;
    font-size: 3rem;
    width: 90%;
    color: black;

    text-align: left;

    font-weight: 800;
}

.info-button {
    color: white;
    user-select: none;
    width: 135px;


    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    background-color: #2a2a2a;
   /* box-shadow: 3px 3px 5px 0 rgba(0,0,0,.51);*/
    margin-top: 0.1rem;
    transition-duration: 0.2s;
    cursor: pointer;

}

.info-button:hover {
    transform: scale(1.1);
}

.info-button > div {
    margin: auto;
    height: 18px;
    font-size:20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*#endregion*/


/*####################### KEYWORD STYLE ##################### */



/*#region KEYWORD */


@media (max-width:800px)
{
    .keyword {
        justify-content: center!important;
    }

}

.keyword {
    margin-top: 3rem;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

}



.keyword > h3 {
    user-select: none;
    font-family: 'DIN', sans-serif;
    font-weight: 800;
    color: black;
    margin: 0.25rem;
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: -0.02rem;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    transition-duration: 0.2s;
    cursor: pointer;
}
.keyword > h3:hover {
    transform: scale(1.1);
}

.one {
    background-color: #fec08c;

}
.two {
    background-color:#fcf2ab;

}
.tree {
    background-color: #aae8d5;

}
.four {
    background-color: #8afeff;

}
.five {
    background-color: #29aaff;

}
.six {
    background-color: #F5B3D1ff;
    background-color: var(--pretty-pink) ;

}

/*#endregion*/

/*###################  PRODUCTS STYLE #################### */
/*#region PRODUCTS */

@media(max-width: 700px)
{
    .product-container > h1
    {
        font-size: 1.5rem;
    }
}

.product-container{
    background-color: var(--white-break);
    padding-top:1rem;
    width: 100%; padding-bottom: 5rem;
    margin-top: 5rem;

}

.product-container > h1 {
    color: black;
    font-family: 'DIN', sans-serif;
    font-weight: 800;
    font-size: 3.5rem;
    letter-spacing: -0.1rem;
    text-transform: uppercase;
}

.products {
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 300px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}



/*#endregion */

/*###################  BLOG STYLE #################### */




/*#region BLOG */

@media (max-width:1100px)
{
.blog-image {
    display: none;
    width: 0%!important;
}
.blog-text-container{
    width: 100%!important;
    align-items: center!important;
}

.article-container {
    align-items: center!important;
}
.article-content {
        text-align : center!important;
    }


}

@media(max-width: 700px)
{
    .blog-container {
        width: 100%!important;

    }


}


.blog-container {
    width: 100%;

    background-color: #2a2a2a;

    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;

    padding: 1rem;
}

.line {
    height: 2px;
    width: 100px;
    background-color: white;
    margin: 0.25rem 0;
}

.blog-image {
    width: 50%;
    background-image: url("../assets/imgs/games.jpg");
    background-size: cover;
    background-position-x: center;
}

.blog-text-container {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}

.article-container{
    display: flex;
    margin: 1rem;

    padding: 0.75rem;
    flex-direction: column;
    align-items: center;

}

.article-title {
    font-family: 'DIN', sans-serif;
    font-weight: 800;
    font-size: 2rem;
    text-transform: uppercase;
}

.article-content {
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    font-size: 1rem;
}

.article-meta{
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    font-weight: 200;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-style: italic;
    font-size: 1rem;

}
/* #endregion */

/*###################  FOOTER STYLE #################### */

/*#region FOOTER */




/* #endregion */
</style>
